require('../scss/index.scss');

import $ from 'jquery';

//When the page is fully loaded
$(function(){
    //Change the opacity to 1
    setTimeout(function(){$("body").css('opacity','1');}, 500);

    if($('body.dealroom-view')[0]) import('./dealroom');
});

$('.accordion').on('click',function(){
    $(this).next().slideToggle();
    $(this).toggleClass('active');
});

// submenu animation

$('.sub-menu-parent').hover(function(e){
    e.preventDefault();
    $(this).find('.sub-menu').slideDown();
    setTimeout(() => {
        $(this).find('.sub-menu').slideDown();
    }, 550);
}, function(){
    setTimeout(() => {
        $(this).find('.sub-menu').slideUp();
    }, 500);
});

$( '.sub-menu-parent' ).mousemove(function( event ) {
    $(this).find('.sub-menu').slideDown();
});

// $('.sub-menu').hover(function(e){
//     e.preventDefault();
//     console.log(e)
//     $(this).slideDown();
//     console.log('open submenu')

// }, function(){


//     setTimeout(() => {
//         console.log('close submenu')
//         $(this).slideUp();
//     }, 300);
// });

// window.onmousemove = function(e){
//     console.log(e.target)
// };

$('.clicked').on('click', function(){
    if ($(this).next().hasClass('sub-menu'))
        $(this).next().slideToggle();
});

$('.sub-menu-parent').on('click', function(){
    $(this).find('.sub-menu').slideToggle();
});

$('.burguer').on('click', function(){
    $('.menu-list').toggleClass('active');
    $('body').toggleClass('overflow');
});

 // Hide long text by default
$('.long-text').each(function() {
var content = $(this).text();
if (content.length > 100) {
    var shortText = content.substring(0, 100);
    var longText = content.length > 100 ? content.substring(100) : "";
    var html = shortText + '<span class="dots">... </span><span class="more">' + longText + '</span><a href="#" class="read-more">Leer más</a>';
    $(this).html(html);
}
else {
    $(this).siblings('.read-more').hide();
}
});

$('.read-more').on('click', function(e) {
e.preventDefault();
$(this).siblings('.more').toggle();
$(this).siblings('.less').toggle();
$(this).siblings('.dots').toggle();
$(this).text(function(_, currentText) {
    return currentText === 'Leer más' ? ' Leer menos' : 'Leer más';
});
});
function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}

function convert_vimeo(input) {
    var pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
    if (pattern.test(input)) {
     var replacement = '<iframe width="100%" height="300" src="//player.vimeo.com/video/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
     var input = input.replace(pattern, replacement);
    }
    return input;
}

let videoIframes = $('[data-iframe-video-src]');

videoIframes.each(function(){
    let item = $(this);
    let url = item.data('iframe-video-src');
    let iframe;
    
    if (url.includes("youtu")) {
        let id = youtube_parser(url);
        let correct_url = 'https://www.youtube.com/embed/' + id + '?modestbranding=1&showinfo=0&rel=0&disablekb=1&color=white&controls=0';
        let width = '100%';
        let height = '300';
        iframe = $(`<iframe src="${correct_url}" 
                            type="text/html" 
                            controls=0
                            disablekb=1
                            frameborder="0"
                            allow="fullscreen;"
                            width="${width}"
                            height="${height}"
                            class="video-iframe">
                        </iframe>`); 
    } else if(url.includes("vimeo")){
        iframe = convert_vimeo(url);
    }

    item.append(iframe);

});





